import logoSvg from './assets/logo-svg.svg';
import './App.scss';


const Logo = () => {
  return (<h3 className='uppercase'>
    <img src={(logoSvg)} height={120} width={130} alt="LogoSVG" />
  </h3>);
}
const VideoClip = (props) => {

  return (
  <div {...props}>
    <video loop autoPlay={true} muted id="clips" playsInline>
      <source src={require('./assets/animazione.mp4')} type='video/mp4' />
    </video>
  </div>);
}

function App() {


  return (
    <div className="App">
      <header className="App-header">
        <div className='container'>
          
          <div className='text-layer'>
            <span className='label-name'>ANGELA LAGANÀ</span>
            <VideoClip className='clip-video' />
            <span className='label-payoff'>HAIR AND SKIN BEAUTY CARE</span>
          </div>
        </div>
      </header>
      <section className='row-cta'>
        <div className='container'>
          <div>
              <h3 className='font-normal'>È un nuovo ed esclusivo progetto di alta cosmesi artigianale.</h3>
              <h3 className='font-normal'>Scopri di più</h3>
              <div className='group-buttons'>
                <a href='./document/ANGELA_LAGANA_ITA.pdf' target="_blank">Ita</a> 
                <a href='./document/ANGELA_LAGANA_ENG.pdf' target="_blank">Eng</a>
              </div>
          </div>
        </div>
      </section>
      <section className='row-image'>
        <div className='container'>
          <div className='content-image'>
              <figure>
                <img src={require('./assets/ANGELA LAGANA 10X10.jpg')} width={600} height={600} alt="Immagine"/>
              </figure>
          </div>
        </div>
      </section>

      <footer className='footer'>
        <div className='container'>
          <div className='center-align'>
              <Logo />
              <p>
                <a href='https://www.instagram.com/angelalagana.it' target="_blank" rel="noreferrer"> @angelalagana.it </a>
              </p>
              <p>
                <a href='mailto:info@angelalagana.it'>INFO@ANGELALAGANA.IT</a>
              </p>
              <br/>
              <p>
                40057 Cadriano di Granarolo dell’Emilia (BO) <br/> +39 051763418
              </p>
          </div>
        </div>
      </footer>

    </div>
  );
}

export default App;